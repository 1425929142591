<template>
  <!--Button and dialog to show the table-->
  <v-container
    :style="$vuetify.breakpoint.mdOnly ? 'max-width:1000px' : ''"
    class="pb-0 px-0"
  >
    <!--TOTAL CLIENTS-->
    <v-row>
      <!-- <v-col cols="12" sm="6" md="4">
        <v-card raised elevation="6" class="mt-1">
          <v-card-title>
            <h4
              style="
                font-size: 15px;
                text-transform: uppercase;
                font-weight: 100;
                text-decoration: underline;
              "
            >
              {{ $t("clients.total") }}
            </h4>
          </v-card-title>
          <v-simple-table style="background-color: #1e1e1e">
            <h2>{{ totalClients }}</h2>
          </v-simple-table>
        </v-card>
      </v-col> -->

      <!-- TOP CLIENTS BY CASH-->
      <v-col cols="4">
        <ClientsTopByCash />
      </v-col>
      <!-- TOP CLIENTS BY CASH-->

      <v-col cols="4">
        <ClientsTopByAppointments />
      </v-col>
      <v-col cols="4">
        <ClientsMoreThanOneService />
      </v-col>
    </v-row>

    <v-row>
      <!--CLIENTS BY DEBTS-->
      <v-col cols="4">
        <ClientsDebts />
      </v-col>

      <!--CLIENTS BY HOW MEET-->
      <v-col cols="4">
        <ClientsHowMeet />
      </v-col>
      <!--CLIENTS BY PAYMENT METHOD-->
      <v-col cols="4">
        <ClientsPaymentMethod />
      </v-col>
    </v-row>
    <v-row>
      <!--CLIENTS BY AGE-->
      <v-col cols="12">
        <ClientsByAge />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ClientsTable",
  components: {
    ClientsTopByCash: () =>
      import("@/components/stats/clients/ClientsTopByCash"),
    ClientsTopByAppointments: () =>
      import("@/components/stats/clients/ClientsTopByAppointments"),
    ClientsByAge: () => import("@/components/stats/clients/ClientsByAge"),
    ClientsDebts: () => import("@/components/stats/clients/ClientsDebts"),
    ClientsHowMeet: () => import("@/components/stats/clients/ClientsHowMeet"),
    ClientsPaymentMethod: () =>
      import("@/components/stats/clients/ClientsPaymentMethod"),
    ClientsMoreThanOneService: () =>
      import("@/components/stats/clients/ClientsMoreThanOneService"),
  },
  data() {
    return {
      dialog: true,
      totalClients: 0,
    };
  },
  mounted() {
    this.reload();
  },
  watch: {
    filters: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("stats", ["filters"]),
  },
  methods: {
    ...mapActions("stats", ["getClients"]),

    reload() {
      this.fetchClients();
    },
    ...mapMutations("stats", ["SET_LIST"]),

    fetchClients() {
      this.getClients({
        paginate: {},
        filters: {},
      }).then((clients) => {
        this.SET_LIST({
          value: clients.topClientsByCash.data,
          list: "topClientsByCash",
        });
        this.SET_LIST({
          value: clients.topClientsByAppointments.data,
          list: "topClientsByAppointments",
        });
        this.SET_LIST({
          value: clients.moreThanOneServiceClients.data,
          list: "moreThanOneServiceClients",
        });
        this.SET_LIST({
          value: clients.clientsByAgeGroup,
          list: "clientsByAgeGroup",
        });
        this.SET_LIST({
          value: clients.debtsByClient,
          list: "debtsByClient",
        });
        this.SET_LIST({
          value: clients.howMeet,
          list: "howMeet",
        });
        this.SET_LIST({
          value: clients.paymentType,
          list: "paymentType",
        });
      });
    },
  },
};
</script>
<style></style>
